module.exports = {
  SUCCESS: 0,
  ERROR: 1,

  MISSING_AUTHENTICATION_TOKEN: 100,
  EXPIRED_TOKEN: 101,
  AUTHORIZER_FAILURE: 102,
  INVALID_SIGNATURE: 103,
  INVALID_SESSION_TOKEN: 104,
  USER_ACCESS_PERMISSION_ERROR: 105,
  CLOUD_CONNECTOR_BAD_REQUEST: 150,
  SYSTEM_UNDER_MAINTENANCE: 160,
  REPRO_CANT_START_SYSTEM_UNDER_MAINTENANCE: 161,

  // Invalid input related error should be less than 1000 code.
  INPUT_USER_NAME_EMPTY: 200,
  INPUT_PASSWORD_EMPTY: 201,
  INVALID_INPUT_MISSING_PARAM: 202,
  INVALID_INPUT_VERIFICATION_CODE: 203,
  LOGIN_FAILED_COUNT_OVER: 204,
  INVALID_PASSWORD_FORMAT: 205,
  INVALID_PASSWORD_LENGTH: 206,
  INVALID_PASSWORD_PARTIAL_MATCH: 207,
  INVALID_PASSWORD_HISTORY: 208,
  INVALID_INIT_PASSWORD_PARAMS: 209,
  INVALID_INPUT_EMAIL: 210,
  INVALID_INPUT_FORCE_REPRO_TARGET_ID: 211,
  SWID_CONSENT_UPDATE_FAILED: 212,
  HWID_CONSENT_UPDATE_FAILED: 213,
  CONTINUOUS_REPRO_UPDATE_OF_CONSENT_ERROR: 214,
  INVALID_INPUT_TYPE_PARAM: 215,

  // flontend 側のネットワーク切断エラーコード
  FRONT_DISCONNECT_NETWORK: 600,
  FRONT_SERVER_NO_RESPONCE: 9999999,
  // 400系と500系のエラーコードを定義する場合は flontend 側と確認すること
  NO_NETWORK_ERROR: 500,
  PERMISSION_ERROR: 403,

  USER_NOT_FOUND: 1100,
  USER_UNAUTHORIZED: 1101,
  USER_ALREADY_LOGGEDIN: 1102,
  USER_NOT_LOGGEDIN: 1103,
  USER_NO_PERMISSION_RELEASE_NOTES: 1104,
  ADMIN_USER_NOT_ADMIN: 1106,
  USERGROUP_FETCH_FAILED: 1107,
  USER_FORBID_PASSWORD_CHANGE: 1108,
  USER_ALREADY_EXISTS: 1109,
  USER_ACCOUNT_EXPIRED: 1110,
  USER_ALREADY_DELETED: 1111,
  USER_DELETE_LOGGEDIN_TOREPRO: 1112,
  USER_DISABLE_LOGGEDIN_TOREPRO: 1113,
  USER_NOT_ACTIVE: 1114,
  DEVICE_NOT_SUPPORTED_FOR_THIS_PROTOCOL: 1200,

  INTDASH_ACCESS_FAILED: 2000,

  DB_USER_INFO_NOT_FOUND: 3000,
  DB_PAIRING_INFO_NOT_FOUND: 3001,
  DB_PAIRING_INFO_UPDATE_FAILED: 3002,
  DB_PFC_FILE_INFO_NOT_FOUND: 3003,
  DB_CSV_ANALYSIS_INFO_NOT_FOUND: 3006,
  DB_COMPANIES_INFO_NOT_FOUND: 3007,
  DB_COMPANY_DEPARTMENT_INFO_FETCH_FAILED: 3008,
  DB_COMPANY_ALREADY_USED: 3012,
  DB_COMPANY_ALREADY_EXISTS: 3013,
  DB_DEPARTMENT_ALREADY_EXISTS: 3014,
  DB_DEPARTMENT_USED_BY_USER: 3015,
  DB_TARGET_UNIT_NOT_FOUND: 3016,
  DB_COUNTRY_ALREADY_EXISTS: 3017,
  DB_COUNTRY_NOT_FOUND: 3018,
  DB_ENGINE_NOT_FOUND: 3019,
  DB_ASPIRATION_NOT_FOUND: 3020,
  DB_TRANSMISSION_NOT_FOUND: 3021,
  DB_REPRO_FACTORS_NOT_FOUND: 3022,
  DB_SHOP_ALREADY_EXISTS: 3023,
  DB_SHOP_INFO_NOT_FOUND: 3024,
  DB_SHOP_ALREADY_USED: 3025,
  DB_OBJECT_MOVEMENT_IN_PROGRESS: 3026,
  DB_COMPANY_CANNOT_MOVE: 3027,
  DB_SHOP_CANNOT_MOVE: 3028,
  DB_USER_CANNOT_MOVE: 3029,
  DB_VEHICLE_MODEL_NOT_FOUND: 3030,
  DB_CANNOT_UPDATE_OLD_DATA: 3031,
  DB_UPLOAD_STATUS_NOT_FOUND: 3032,
  DB_PFC_FILE_DETAILS_NOT_FOUND: 3033,
  DB_PFC_FILE_DETAILS_UPDATE_FAILED: 3034,
  DB_PFC_FILE_DETAILS_DELETE_FAILED: 3035,
  DB_PFC_APPROVAL_NOT_FOUND: 3036,
  DB_PFC_APPROVAL_UPDATE_FAILED: 3037,
  DB_PFC_APPROVAL_DELETE_FAILED: 3038,
  DB_PFC_FILE_ALREADY_PUBLISHED: 3039,
  DB_USER_TO_APPROVE_NOT_FOUND: 3040,
  DB_REPRO_TARGET_UNIT_NOT_FOUND: 3041,
  DB_TARGET_UNIT_ALREADY_EXISTS: 3042,
  DB_ENGINE_ALREADY_EXISTS: 3043,
  DB_ASPIRATION_ALREADY_EXISTS: 3044,
  DB_TRANSMISSION_ALREADY_EXISTS: 3045,
  DB_REPRO_FACTORS_ALREADY_EXISTS: 3046,
  DB_COUNTRY_GROUP_ALREADY_EXISTS: 3047,
  DB_COMPANY_CODE_ALREADY_EXISTS: 3048,
  DB_SHOP_CODE_ALREADY_EXISTS: 3049,
  DB_VEHICLE_MODEL_DISPLAY_ALREADY_EXISTS: 3050,
  DB_VEHICLE_MODEL_DISPLAY_NOT_FOUND: 3051,
  DB_VEHICLE_MODEL_DISPLAY_ALREADY_USED: 3052,
  DB_PFC_FILE_COUNTRY_GROUP_ALREADY_EXISTS: 3053,
  DB_CANID_MODEL_ALREADY_EXISTS: 3054,
  DB_CANID_MODEL_NOT_FOUND: 3055,
  DB_PFC_FILE_TARGET_ID_NOT_MATCH: 3056,
  DB_PFC_FILE_INVALID_FOR_TWO_STEP_PAIRING: 3057,
  DB_PFC_APPROVAL_NOT_ALLOW: 3058,
  DB_JUDGMENT_SORTKEY_ALREADY_EXISTS: 3059,
  DB_JUDGMENT_SORTKEY_INFO_NOT_FOUND: 3060,
  DB_PFC_FILE_ALREADY_REPROGRAM: 3061,
  DB_DMAT_EDGE_NOT_FOUND: 3062,
  // 最新以外のPFCファイルの承認ステータスを未承認からDistributor Onlyに変更した場合のエラー
  DB_PFC_APPROVAL_NOT_ALLOW_DISTRIBUTOR_ONLY: 3065,
  // 指定されたマニュアルが存在しないときのエラー
  DB_NOT_FOUND_MANUAL_FILE: 3066,

  CLOUD_CONNECTOR_VERSION_WRONG: 4000,
  CLOUD_CONNECTOR_NOT_ACTIVE: 4001,
  CLOUD_CONNECTOR_USEDBY_OTHER: 4002,
  CLOUD_CONNECTOR_PAIRING_RSP_NOT_RECEIVED: 4003,
  CLOUD_CONNECTOR_INQUIRY_RSP_WRONG: 4004,
  CLOUD_CONNECTOR_INQUIRY_VEHICLE_ALREADY_UPDATED: 4005,
  CLOUD_CONNECTOR_INQUIRY_INVALID_TARGET_ECU: 4006,
  CLOUD_CONNECTOR_INQUIRY_RSP_NOT_RECEIVED: 4007,
  CLOUD_CONNECTOR_VEHICLE_COMMUNICATION_ERROR: 4008,
  CLOUD_CONNECTOR_INTERNAL_ERROR: 4009,
  CLOUD_CONNECTOR_USER_NOT_PAIRED: 4010,
  CLOUD_CONNECTOR_PFC_FILE_DOWNLOAD_FAILED: 4011,
  CLOUD_CONNECTOR_REPRO_EXECUTION_INPROGRESS: 4012,
  CLOUD_CONNECTOR_INVALID_J2534_DEVICE: 4013,
  CLOUD_CONNECTOR_SEQUENCE_APP_NOT_FOUND: 4014,
  CLOUD_CONNECTOR_SEQUENCE_APP_INIT_FAILED: 4015,
  CLOUD_CONNECTOR_REPRO_EXECUTE_FAILED: 4016,
  CLOUD_CONNECTOR_J2534_DEVICE_OPEN_FAILED: 4017,
  CLOUD_CONNECTOR_J2534_DEVICE_CONNECT_FAILED: 4018,
  CLOUD_CONNECTOR_DISCONNECT_NETWORK: 4019,
  CLOUD_CONNECTOR_READ_RXSWIN_FAILED: 4020,
  CLOUD_CONNECTOR_READ_RXSWIN_FAILED_2: 4020,
  CLOUD_CONNECTOR_UPDATE_RXSWIN_FAILED: 4021,
  CLOUD_CONNECTOR_UPDATE_RXSWIN_FAILED_2: 4021,
  CLOUD_CONNECTOR_READ_RXSWIN_FAILED_BEFORE_UPDATE: 4022,

  // MAC key download sequence
  MAC_KEY_SEQUENCE_NOT_FOUND: 4023,
  // IMB DLL download sequence
  IMB_SEQUENCE_NOT_FOUND: 4024,

  // Sold Vehicle error code
  SOLD_VEHICLE_PAK_FILE_DOWNLOAD_FAILED: 4027,
  SOLD_VEHICLE_FLASH_WRITE_ALREADY_STARTED: 4028,
  SOLD_VEHICLE_FLASH_WRITE_NOT_INSTALLED: 4029,
  SOLD_VEHICLE_FLASH_WRITE_LAUNCH_FAILED: 4030,

  VEHICLE_INVALID_VOLTAGE_INIT: 4300,
  VEHICLE_INVALID_VOLTAGE_RXSWIN: 4301,

  SEQUENCE_APP_ERR_INIT: 4500,
  SEQUENCE_APP_ERR_WRONG_ID: 4501,
  SEQUENCE_APP_ERR_WRONG_ID_NEW: 4502,
  SEQUENCE_APP_ERR_CANNOT_ID_COMMINICATION: 4503,
  SEQUENCE_APP_ERR_FORCED_WRITE: 4504,
  SEQUENCE_APP_ERR_WRONG_ENGINE_RPM: 4505,
  SEQUENCE_APP_ERR_WRONG_SPEED: 4506,
  SEQUENCE_APP_ERR_WRONG_VOLTAGE: 4507,
  SEQUENCE_APP_ERR_WRONG_IDLING_STOP: 4508,
  SEQUENCE_APP_ERR_CANNOT_CHANGE_GWMODE: 4509,
  SEQUENCE_APP_ERR_NOT_ALLOWED: 4510,
  SEQUENCE_APP_ERR_CANNOT_ROM_DELETE: 4511,
  SEQUENCE_APP_ERR_CANNOT_DATA_DOWNLOAD: 4512,
  SEQUENCE_APP_ERR_CANNOT_DATA_TRANSFER: 4513,
  SEQUENCE_APP_ERR_CANNOT_DATA_COMPLETE: 4514,
  SEQUENCE_APP_ERR_CANNOT_SUM_REQUEST: 4515,
  SEQUENCE_APP_ERR_WRONG_SUM: 4516,
  SEQUENCE_APP_ERR_WRONG_ID_AFTER_WRITE: 4517,
  SEQUENCE_APP_ERR_CANNOT_SECURITY_TIMEOUT: 4518,
  SEQUENCE_APP_ERR_CANNOT_EXTERNAL_TIMEOUT: 4519,
  SEQUENCE_APP_ERR_CANNOT_EXTERNAL_ERROR: 4520,
  SEQUENCE_APP_ERR_CANNOT_CGW_TIMEOUT: 4521,
  SEQUENCE_APP_ERR_CANNOT_IGOFFON_ERROR: 4522,
  SEQUENCE_APP_ERR_CANNOT_OPEN_DEVICE: 4523,
  SEQUENCE_APP_ERR_CANNOT_CONNECT_DEVICE: 4524,
  SEQUENCE_APP_ERR_CANNOT_DIAG_COMMINICATION: 4525,
  SEQUENCE_APP_ERR_SYSTEM_ERROR: 4526,
  SEQUENCE_APP_ERR_JSON_FILE_INCORRECT: 4527,
  SEQUENCE_APP_ERR_CANNOT_CGW_RESPONSE_SEED: 4528,
  SEQUENCE_APP_ERR_CANNOT_CGW_CLOUD_ERROR: 4529,
  SEQUENCE_APP_ERR_CANNOT_CGW_CLOUD_TIMEOUT: 4530,
  SEQUENCE_APP_ERR_CANNOT_CGW_RESPONSE_KEY_OPEN: 4531,
  SEQUENCE_APP_ERR_CANNOT_CGW_RESPONSE_SID_UNFILTER: 4532,
  SEQUENCE_APP_ERR_CANNOT_SECURITY_CLOUD_ERROR: 4533,
  SEQUENCE_APP_ERR_CANNOT_SECURITY_RESPONSE_SEED: 4534,
  SEQUENCE_APP_ERR_CANNOT_SECURITY_RESPONSE_KEY_OPEN: 4535,
  SEQUENCE_APP_ERR_CANNOT_SWITCH_DIAG_SESSION: 4536,
  SEQUENCE_APP_ERR_CANNOT_START_REPROGRAMMING: 4537,
  SEQUENCE_APP_ERR_ECU_STATE_INITIALIZING: 4538,
  SEQUENCE_APP_ERR_CANNOT_WRITE_ADDRESS_OFFSET: 4540,
  SEQUENCE_APP_ERR_CANNOT_WRITE_KEY_SEED: 4541,
  SEQUENCE_APP_ERR_CANNOT_WRITE_NONCE: 4542,
  SEQUENCE_APP_ERR_CANNOT_CONFIG_RECEIVE_DELETE_ROUTINE: 4543,
  SEQUENCE_APP_ERR_CANNOT_DATA_TRANSFER_DELETE_ROUTINE: 4544,
  SEQUENCE_APP_ERR_CANNOT_DATA_TRANSFER_COMPLETE_DELETE_ROUTINE: 4545,
  SEQUENCE_APP_ERR_WRONG_KEY_VERIFICATION: 4546,
  SEQUENCE_APP_ERR_CANNOT_MEMORY_CLEAR_AREA: 4547,
  SEQUENCE_APP_ERR_CANNOT_CONFIG_RECEIVE_REPRO_DATA: 4548,
  SEQUENCE_APP_ERR_CANNOT_DATA_TRANSFER_REPRO_DATA: 4549,
  SEQUENCE_APP_ERR_CANNOT_DATA_TRANSFER_COMPLETE_REPRO_DATA: 4550,
  SEQUENCE_APP_ERR_CANNOT_CHECK_REWRITE_DATA_AREA: 4551,
  SEQUENCE_APP_ERR_CANNOT_SERVER_RESET: 4552,
  SEQUENCE_APP_ERR_CANNOT_CONFIRM_VEHICLE_STATUS: 4553,
  SEQUENCE_APP_ERR_WRONG_AREA_INDICATED: 4554,

  // DataMonitorActiveTestSequence.dll
  SEQUENCE_APP_ERR_DMAT_SYSTEM_ERROR: 4800,
  SEQUENCE_APP_ERR_DMAT_INTDASH_START_FAILED: 4801,

  PFC_FILE_UPLOAD_FAILED: 5000,
  PFC_FILE_DOWNLOAD_FAILED: 5001,
  PFC_FILE_UNZIP_FAILED: 5002,
  PFC_FILE_CONTENT_EMPTY: 5003,
  PFC_FILE_NO_CSV_FILE: 5004,
  PFC_FILE_CSV_PARSING_FAILED: 5005,
  PFC_FILE_INVALID_CSV_FILE: 5006,
  PFC_FILE_INVALID_PFC_FILE: 5007,
  PFC_FILE_INVALID_MOT_FILE: 5008,
  PFC_FILE_LOCK_FAILED: 5009,
  TEST_PFC_CANT_PUBLISHED: 5010,
  PFC_FILE_TARGET_ALREADY_REGISTERED: 5011,
  PFC_FILE_INCLUDE_LOOP_INFORMATION: 5012,
  PFC_FILE_NEW_ID_REPEATED: 5013,
  PFC_FILE_MULTIPLE_NEW_ID_FOR_TARGET_ID: 5014,
  PFC_FILE_TREE_MORE_THAN_ONCE_REPRO: 5015,
  PFC_FILE_SAME_GROUP_ALREADY_UPLOADED: 5016,
  PFC_FILE_EYE_SYSTEM_ONLY_SINGLE_SYSTEM: 5017,
  PFC_FILE_TWO_STEP_TEST_PFC_NOT_SUPPORTED: 5018,
  PFC_FILE_TWO_STEP_MULTIPLE_SYSTEMS_NOT_SUPPORTED: 5019,
  PFC_FILE_TWO_STEP_SYSTEMS_NOT_SUPPORTED: 5020,
  PFC_FILE_TWO_STEP_MULTIPLE_GROUPS_NOT_SUPPORTED: 5021,
  PFC_FILE_TWO_STEP_MASTER_CHILD_SYSTEM_NOT_MATCHING: 5022,
  PFC_FILE_TWO_STEP_MASTER_CHILD_TARGETS_NOT_MATCHING: 5023,
  PFC_FILE_TWO_STEP_VIA_ID_REPEATED_IN_NEW_PFC: 5024,
  PFC_FILE_NOT_ALL_NEWID_MATCHED: 5025,
  PFC_FILE_SYSTEM_NAME_IS_NOT_CCU: 5026,
  PFC_FILE_TREE_MORE_THAN_ONCE_REPRO_PUBLISH: 5027,
  PFC_FILE_NOT_MATCHED_TARGETID_IN_ALL_FILES: 5028,
  PFC_FILE_SAME_SYSTEM_IN_BATCH_UPLOAD: 5029,
  PFC_FILE_NOT_CONTAINS_ALL_TARGET_ID: 5030,
  PFC_FILE_NOT_CONTAINS_ONLY_FORCE_ENABLED_NEW_ID: 5031,
  PFC_FILE_RESTORE_NOT_ONLY_MERCHANTABILITY_ENABLED_BASE_ID: 5032,
  PFC_FILE_MERCHANTABILITY_MATCH_MERCHANTABILITY_ENABLED_TARGET_ID: 5033,
  PFC_FILE_CONTAINS_MERCHANTABILITY_ENABLED_NEW_ID: 5035,
  PFC_FILE_TEST_PFC_REPRO_TYPE_RESTORE: 5036,
  PFC_FILE_TWO_STEP_REPRO_TYPE_RESTORE: 5037,
  PFC_FILE_RESTORE_NOT_ONLY_MERCHANTABILITY_ENABLED_NEW_ID: 5038,
  PFC_FILE_NOT_CONTAINS_ALL_TARGET_ID_PUBLISH: 5039,
  PFC_FILE_CONTAINS_MERCHANTABILITY_ENABLED_NEW_ID_PUBLISH: 5040,
  PFC_FILE_REPRO_TYPE_NOT_SET_PUBLISH: 5041,
  PFC_FILE_NEW_ID_REPEATED_PUBLISH: 5042,
  PFC_FILE_NOT_CONTAINS_ALL_TARGET_ID_REPRO_TYPE_CHANGE: 5043,
  PFC_FILE_NOT_CONTAINS_ONLY_FORCE_ENABLED_NEW_ID_REPRO_TYPE_CHANGE: 5044,
  PFC_FILE_RESTORE_NOT_ONLY_MERCHANTABILITY_ENABLED_BASE_ID_REPRO_TYPE_CHANGE: 5045,
  PFC_FILE_MERCHANTABILITY_MATCH_MERCHANTABILITY_ENABLED_TARGET_ID_REPRO_TYPE_CHANGE: 5046,
  PFC_FILE_CONTAINS_MERCHANTABILITY_ENABLED_NEW_ID_REPRO_TYPE_CHANGE: 5047,
  PFC_FILE_TEST_PFC_REPRO_TYPE_RESTORE_REPRO_TYPE_CHANGE: 5048,
  PFC_FILE_TWO_STEP_REPRO_TYPE_RESTORE_REPRO_TYPE_CHANGE: 5049,
  PFC_FILE_RESTORE_NOT_ONLY_MERCHANTABILITY_ENABLED_NEW_ID_REPRO_TYPE_CHANGE: 5050,
  PFC_FILE_NEW_ID_REPEATED_REPRO_TYPE_CHANGE: 5051,
  PFC_FILE_ALREADY_PUBLISHED_REPRO_TYPE_CHANGE: 5052,
  PFC_FILE_SAME_GROUP_ALREADY_UPLOADED_REPRO_TYPE_CHANGE: 5053,
  PFC_FILE_NOT_CONTAINS_IN_SUB_GROUP_NEW_ID: 5054,
  PFC_FILE_EXIT_ONLY_EYE_TWO: 5055,
  PFC_FILE_CONTAINS_EYE_TWO_ID_ALL_ZERO: 5056,
  PFC_FILE_NOT_CONTAINS_IN_SUB_GROUP_NEW_ID_REPRO_TYPE_CHANGE: 5057,
  PFC_FILE_SAME_GROUP_ALREADY_UPLOADED_PUBLISH: 5058,
  PFC_FILE_FROM_MAIN_SEQUENCE_TO_SUBSERIES: 5059,
  PFC_FILE_FROM_SUBSERIES_TO_MAIN_SEQUENCE: 5060,
  PFC_FILE_NOT_STRADDLE_MAIN_AND_SUBSERIES: 5061,
  PFC_FILE_TARGET_ID_AND_NEW_ID_IS_BACKDATE_REPRO_ID: 5062,
  PFC_FILE_TARGET_ID_INCLUDES_PUBLISHED_MERCHANTABILITY_REPRO_ID: 5063,
  PFC_FILE_TARGET_AND_NEW_ID_INCLUDES_MERCHANTABILITY_BACKDATE_REPRO_ID: 5064,
  PFC_FILE_FROM_MAIN_SEQUENCE_TO_SUBSERIES_REPRO_TYPE_CHANGE: 5065,
  PFC_FILE_FROM_SUBSERIES_TO_MAIN_SEQUENCE_REPRO_TYPE_CHANGE: 5066,
  PFC_FILE_NOT_STRADDLE_MAIN_AND_SUBSERIES_REPRO_TYPE_CHANGE: 5067,
  PFC_FILE_TARGET_ID_AND_NEW_ID_IS_BACKDATE_REPRO_ID_REPRO_TYPE_CHANGE: 5068,
  PFC_FILE_TARGET_ID_INCLUDES_PUBLISHED_MERCHANTABILITY_REPRO_ID_REPRO_TYPE_CHANGE: 5069,
  PFC_FILE_TARGET_AND_NEW_ID_INCLUDES_MERCHANTABILITY_BACKDATE_REPRO_ID_REPRO_TYPE_CHANGE: 5070,
  PFC_FILE_FROM_MAIN_SEQUENCE_TO_SUBSERIES_PUBLISH: 5071,
  PFC_FILE_FROM_SUBSERIES_TO_MAIN_SEQUENCE_PUBLISH: 5072,
  PFC_FILE_NOT_STRADDLE_MAIN_AND_SUBSERIES_PUBLISH: 5073,
  PFC_FILE_TARGET_ID_AND_NEW_ID_IS_BACKDATE_REPRO_ID_PUBLISH: 5074,
  PFC_FILE_TARGET_ID_INCLUDES_PUBLISHED_MERCHANTABILITY_REPRO_ID_PUBLISH: 5075,

  IMPORT_CSV_FILE_FORMAT_INVALID: 6000,
  TELEMATICS_ECU_NOT_INSTALLED: 6001,
  IMPORT_CSV_FILE_HAS_MORE_RECORDS: 6002,
  IMPORT_CSV_FILE_HAS_INVALID_INFORMATION: 6003,
  IMPORT_CSV_FILE_COLUMN_DATA_EMPTY: 6004,
  IMPORT_CSV_FILE_COLUMN_DATA_INVALID_LENGTH: 6005,
  IMPORT_CSV_FILE_COLUMN_DATA_INVALID_EMAIL: 6006,
  IMPORT_CSV_FILE_COLUMN_DATA_LANGUAGE_INVALID: 6007,
  IMPORT_CSV_FILE_COLUMN_DATA_AUTH_GROUP_INVALID: 6008,
  IMPORT_CSV_FILE_COLUMN_DATA_ROOT_AUTH_GROUP_INVALID: 6009,
  IMPORT_CSV_FILE_COLUMN_DATA_COMPANY_SHOP_NOT_EXISTS: 6010,
  IMPORT_CSV_FILE_COLUMN_DATA_COMPANY_NAME_INVALID: 6011,
  IMPORT_CSV_FILE_COLUMN_DATA_SHOP_NAME_INVALID: 6012,
  IMPORT_CSV_FILE_COLUMN_DATA_USER_ALREADY_EXISTS: 6013,
  IMPORT_CSV_FILE_COLUMN_DATA_USER_NOT_FOUND: 6014,
  IMPORT_CSV_FILE_COLUMN_DATA_USER_CANT_MOVE: 6015,
  IMPORT_CSV_FILE_COLUMN_DATA_DUPLICATED_RECORD: 6016,
  IMPORT_CSV_FILE_COLUMN_DATA_INVALID_CHARACTERS: 6017,
  IMPORT_CSV_FILE_COLUMN_DATA_SHOP_ALREADY_DELEGATED: 6018,
  IMPORT_CSV_FILE_COLUMN_DATA_MISSING_HEADER: 6019,
  EXPORT_EVENT_LOG_NOT_FOUND: 6020,



  // Server errors
  INTERNAL_SERVER_ERROR: 100000,
  TOO_MANY_REQUESTS_EXCEPTION: 100001,
  LIMIT_EXCEEDED_EXCEPTION: 100002,
  AWS_EXCEPTION: 100003,

  IOT_PAIRING_MESSAGE_SEND_FAILED: 110000,
  IOT_INQUIRY_MESSAGE_SEND_FAILED: 110001,
  IOT_REPRO_START_MESSAGE_SEND_FAILED: 110002,
  IOT_IGON_MESSAGE_SEND_FAILED: 110003,
  IOT_LATEST_REPRO_STATE_MESSAGE_SEND_FAILED: 110004,
  IOT_DISCONNECT_MESSAGE_SEND_FAILED: 110005,
  IOT_MAC_UPDATE_SEND_END_NOTICE_MESSAGE_FAILED: 110006,
  IOT_IMB_START_READING_SEND_FAILED: 110007,


  DB_SYSTEM_INFO_NOT_FOUND: 120000,
  DB_CLOUD_CONNECTOR_INFO_NOT_FOUND: 120001,
  DB_SYSTEM_HISTORY_INFO_NOT_FOUND: 120002,
  DB_SYSTEM_RESPONSE_INFO_NOT_FOUND: 120003,
  DB_EXCLUDED_ECU_INFO_NOT_FOUND: 120004,
  DB_ECU_SYSTEMS_NOT_FOUND: 120005,
  DB_ECU_SYSTEMS_FETCH_FAILED: 120006,

  PFC_FILE_ROOT_FOLDER_CREATE_FAILED: 130000,
  PFC_JSON_FILE_CREATION_FAILED: 130001,

  // mac key error codes
  MAC_KEY_DEVICE_OPEN_ERROR: 7000,
  MAC_KEY_UNABLE_TO_CONNECT: 7001,
  MAC_KEY_COMMUNICATION_ERROR: 7002,
  MAC_KEY_SYSTEM_ERROR_ONE: 7003,
  MAC_KEY_SYSTEM_ERROR_TWO: 7004,
  MAC_KEY_CENTRAL_GATEWAY_AUTHENTICATION_FAILED_PERFORM_FAILURE: 7005,
  MAC_KEY_ERROR_OCCURRED_DURING_IG_OFF_ON: 7006,
  MAC_KEY_CENTRAL_GATEWAY_AUTHENTICATION_FAILED_ERR_RETURNED_FROM_CLOUD: 7007,
  MAC_KEY_CENTRAL_GATEWAY_AUTHENTICATION_FAILED_NO_RESPONSE_FROM_CLOUD: 7008,
  MAC_KEY_CENTRAL_GATEWAY_AUTHENTICATION_FAILED: 7009,
  MAC_KEY_CLOUD_SYSTEM_FAILURE_HAPPENED: 7010,
  DIAG_COMM_ERROR_CODE: 7011,
  VEH_NOT_ELIGIBLE_ERROR_CODE: 7012,
  VEH_LOW_VOLTAGE_ERROR_CODE: 7013,
  IG_ON_RETRY_ERROR_CODE: 7014,
  MAC_KEY_FAILED_TO_UPDATE: 7016,
  FAILED_ERROR_CODE: 7017,
  MAC_SPECIFIC_DTC_ERROR_CODE: 7018,
  NO_KEY_UPDATE_ERROR_CODE: 7019,
  MAC_KEY_UPLOAD_DOWNLOAD_ERROR: 7020,
  MAC_KEY_UPLOAD_DOWNLOAD_TIMEOUT: 7021,
  MAC_KEY_JSON_FILE_INCORRECT: 7022,
  NO_RESPONSE_FROM_MAC_KEY_MANAGEMENT_MASTER: 7023,
  MAC_KEY_END_IG_OFF_ON_ERROR: 7024,
  NO_RESPONSE_FROM_COMMUNICATION_TARGET_UNIT: 7025,
  AUTH_KEY_VEH_NOT_ELIGIBLE_ERROR: 7026,
  AUTH_KEY_ABNORMAL_NOTIFICATION: 7027,
  DB_MAC_STATUS_INFO_NOT_FOUND: 7100,
  MAC_REGIONAL_INFO_NOT_FOUND: 7101,

  // Immobilizer error codes
  IMB_REGIONAL_INFO_NOT_FOUND: 7150,
  IMB_SYSTEM_INFO_NOT_FOUND: 7151,

  // get no register access keys
  IMB_KEY_REG_UNSUCCESSFUL_ERROR: 8017,
  IMB_KEY_DELETION_ERROR: 8019,
  IMB_ONE_KEY_MUST_BE_REGISTERED: 8039,
  IMB_ONE_KEY_MUST_BE_REGISTERED_TYPE_1: 8015,
  IMB_SSM_COMMUNICATION_ABNORMALITY_ERROR: 8011,

  // ecu registration error codes
  IMB_NO_SECURITY_ACCESS_ERROR: 8029,
  IMB_UNSET_FALURE_ERROR: 8034,
  IOCP_RCID_COMMUNICATION_ERROR: 8012,
  IOCP_RCID_NUMBER_OF_REGISTERED_KEYS_ERROR: 8013,

  IMB_CANNOT_CONNECT_DEVICE_ERROR: 8001,
  IMB_SYSTEM_ERROR: 8003,
  IMB_SSM_COMMUNICATION_ERROR: 8028,
  IMB_CANNOT_OPEN_DEVICE_ERROR: 8000,
  IMB_BODY_INTEGRATED_SECURITY_ACCESS_FAILURE_ERROR: 8032,
  IMB_ECM_REGISTRATION_FAILED_ERROR: 8018,
  IMB_CANNOT_CONFIRM_IGON_ERROR: 8033,
  IMB_NUM_OF_REGISTERED_KEYS_EXCEEDED: 8014,
  IMB_SYSTEM_RESTART_ERROR: 8052,
  IMB_UNSUCCESSFUL_COMBINATION_METER_SECURITY_CM_REGISTRATION: 8053,
  IMB_TIMEOUT_DURING_IGNITION_SWITCH_ON_ERROR: 8047,
  IMB_TIMEOUT_DURING_IGNITION_SWITCH_OFF_ERROR: 8046,
  IMB_NUMBER_OF_ACCESSKEY_REGISTRATIONS_EXCEEDED_ERROR: 8045,
  IMB_TIMEOUT_OCCURRED_DURING_ACCESS_KEY_REGISTRATION_ERROR: 8044,
  IMB_TIMEOUT_DURING_ACCESS_KEY_COLLATION_ERROR: 8043,
  IMB_CANNOT_CONFIRM_ACCESS_KEY_ERROR: 8042,
  IMB_CANNOT_CONFIRM_CURRENT_STATUS_ERROR: 8041,
  IMB_ABNORMAL_TERMINATION_ERROR: 8050,
  IMB_ACCESS_KEY_IS_NOT_STILL_REGISTERED: 8051,
  IMB_CANNOT_JUDGE_REGISTER_MODE_ERROR: 8016,
  IMB_VEHICLE_NOT_SUPPORTED_ERROR: 8055,
  IMB_REGISTRATION_HISTORY_COMMUNICATION_ERROR: 8056,
  IMB_ACCESS_KEY_ID_DELETION_ERROR: 8049,
  IMB_TIMEOUT_OCCURED_DURING_REGISTRATION_ERROR: 8048,
  IMB_REMOTE_ENGINE_START_CM_REGISTRATION_UNSUCCESSFUL_ERROR: 8020,
  IMB_TELEMATICS_CM_REGISTARTION_UNSUCCESSFUL_ERROR: 8021,
  IMB_CENTRAL_GATEWAY_REGISTRATION_FAILURE_ERROR: 8022,
  IMB_CENTRAL_GATEWAY_NOT_COMPLETE_ERROR: 8023,
  IMB_CENTARL_GATEWAY_AUTHORIZATION_FAILURE_CLOUD_ERROR: 8007,
  IMB_CENTRAL_GATEWAY_CLOUD_NO_RESPONSE_AUTHORIZATION_ERROR: 8008,
  IMB_REGISTRATION_HISTORY_READ_RESULT_ERROR: 8057,
  IMB_NO_REGISTERED_KEY_ERROR: 8058,
  IMB_CANCEL_IGON_ERROR: 8059,

  // remote engine start cu error codes

  IMB_COMMUNICATION_FAILURE_ERROR: 8024,
  IMB_SECURITY_ACCESS_FAILURE_ERROR: 8025,
  IMB_MODE_TRANSITION_FAILURE_ERROR: 8026,
  IMB_FAILED_TO_REGISTRATION_ERROR: 8027,

  ERROR_IMMOBILIZER_REGISTRATION_ENDED: 8038,
  ERROR_COMBINATION_METER_SECURITY_CM_IS_NOT_COMPLETED: 8036,
  ERROR_COMBINATION_METER_SECURITY_CM_REGISTRATION_FAILURE: 8037,
  ERROR_UNSUCCESSFUL_CGR_MAY_NOT_BE_REGISTERED: 8054,

  // Plan management error codes
  PLAN_IN_USE: 9000,
  PLAN_ALREADY_ABOLISHED: 9001,
  PLAN_CANNOT_BE_PRIVATE: 9002,
  PLAN_CANNOT_BE_CHANGED_USER_SIGNED_IN: 9003,

  // VRT file error codes
  VRT_FILE_UPLOAD_FAILED: 10000,
  VRT_FILE_CONTENT_EMPTY: 10001,
  VRT_FILE_NO_CSV_FILE: 10002,
  VRT_FILE_CSV_PARSING_FAILED: 10003,
  VRT_FILE_INVALID_CSV_FILE: 10004,

  // Work Support error codes
  WSP_GENERAL_ERROR: 40000000,
  WSP_INITIALIZE_FAILED: 40000001,
  WSP_DID_IS_NOT_SUPPORTED_ERROR: 40002001,
  WSP_EXECUTION_CONDITION_FAILURE_ERROR: 40007001,
  WSP_DB_READ_DATA_INFO_NOT_FOUND: 40012001,
  WSP_COMMUNICATION_ERROR: 40051001,
  WSP_UNREGISTERED_ERROR: 40051002,
  WSP_047_DB_READ_DATA_INFO_NOT_FOUND: 40047001,
  WSP_052_NO_DATA: 40052001,
  WSP_053_INIT_FAILED: 40053001,
  WSP_061_TIMEOUT_ERROR: 40061001,
  WSP_061_COMMUNICATION_ERROR: 40061002,
  WSP_062_INITIALIZATION_FAILED: 40062001,
  WSP_066_GET_VARIANTDATA_FAILED: 40066001,
  WSP_067_CONDITION_FAILED: 40067001,
  WSP_067_LIGHTING_DRIVE_ERROR: 40067002,
  WSP_067_AXIS_ADJUSTMENT_INSTRUCTIONS: 40067003,
  WSP_067_AXIS_ADJUSTMENT_FAILED: 40067004,
  WSP_069_COMMUNICATION_ERROR: 40069001,
  WSP_070_START_PROCESS_ERROR: 40070001,
  WSP_070_VISUAL_CONFIRMATION_FAILURE: 40070002,
  WSP_070_STOP_PROCESS_ERROR: 40070003,
  WSP_070_COMMUNICATION_ERROR: 40070004,
  WSP_071_START_PROCESS_ERROR: 40071001,
  WSP_071_STOP_PROCESS_ERROR: 40071002,
  WSP_071_COMMUNICATION_ERROR: 40071003,
  WSP_071_TO_STOP_COMMUNICATION_ERROR: 40071004,
  WSP_071_CALIBRATION_FAILED: 40071005,
  WSP_072_TAKEOVER_ERROR: 40072001,
  WSP_072_TAKEOVER_FAILURE: 40072002,
  WSP_072_COMMUNICATION_ERROR: 40072003,
  WSP_073_CHECK_ERROR: 40073001,
  WSP_073_ERROR_FINISH: 40073002,
  WSP_073_RESULT_ERROR: 40073003,
  WSP_073_FINISH_PROCESS_ERROR: 40073004,
  WSP_073_COMMUNICATION_ERROR: 40073005,
  WSP_074_ERROR_FINISH: 40074001,
  WSP_074_TIMEOUT_ERROR: 40074002,
  WSP_074_COMMUNICATION_ERROR: 40074003,
  WSP_074_SECURITY_ERROR: 40074004,
  WSP_075_SECURITY_ERROR: 40075001,
  WSP_075_ERROR_FINISH: 40075002,
  WSP_075_COMMUNICATION_ERROR: 40075003,
  WSP_078_SECURITY_ERROR: 40078002,
  WSP_078_ERROR_FINISH: 40078003,
  WSP_078_TIMEOUT_ERROR: 40078004,
  WSP_078_COMMUNICATION_ERROR: 40078005,
  WSP_INDIVIDUAL_ERROR: 40079001,
  WSP_080_INITIALIZATION_FAILED: 40080001,
  WSP_081_EXECUTE_FAILED: 40081001,
  WSP_082_READ_SPECIAL_FAILED: 40082001,
  WSP_082_STOP_FAILED: 40082002,
  WSP_083_DELETE_FAILED: 40083001,
  WSP_089_SESSION_ERROR: 40089001,
  WSP_094_ROUTINE_ERROR: 40094001,
  WSP_101_NON_RESPONSE_ERROR: 40101001,
  WSP_111_UNABLE_TO_START: 40111001,
  WSP_111_UNABLE_TO_END: 40111002,
  WSP_112_EXECUTE_FAILED_ERROR: 40112001,
  WSP_112_SENSOR_VALUE_ERROR: 40112002,
  WSP_113_READ_FAILED: 40113001,
  WSP_114_WHEEL_OVERSPEED: 40114001,
  WSP_114_PARAMETER_SELECTION_NOT_POSSIBLE: 40114002,
  WSP_114_WRITE_FAILURE: 40114003,
  WSP_114_INCORRECT_DISPLAY_OF_VARIANT_DATA: 40114004,
  WSP_114_SUPPLEMENTARY_PARTS_INSTALLATION: 40114005,
  WSP_114_NO_MATCH_DATA: 40114006,
  WSP_114_INVALID_FORMAT: 40114007,
  WSP_115_GET_VARIANT_DATA_FAILED: 40115001,
  WSP_115_CHECK_VARIANT_DATA_FAILED: 40115002,
  WSP_115_OVER_SPEED_FAILED: 40115003,
  WSP_115_REGISTERED_FAILED: 40115004,
  WSP_115_SELECT_ERROR_FAILED: 40115005,
  WSP_115_APPLIED_MODEL_ANOMALY_FAILED: 40115006,
  WSP_115_APPLIED_MODEL_UNDEFINED: 40115007,
  WSP_115_VARIANT_DATA_FRAUD: 40115008,
  WSP_116_APPLIED_UNDEFINED: 40116001,
  WSP_116_APPLIED_FAILED: 40116002,
  WSP_117_EXCESSIVE_WHEEL_SPEED: 40117001,
  WSP_117_ABNORMAL_PRESSURE_SENSOR_VALUE: 40117002,
  WSP_117_UNABLE_TO_START_OR_EXIT_MAINTENANCE_MODE: 40117003,
  WSP_118_EXCESSIVE_WHEEL_SPEED: 40118001,
  WSP_118_ABNORMAL_PRESSURE_SENSOR_VALUE: 40118002,
  WSP_118_BRAKE_RELEASE_FAILURE: 40118003,
  WSP_118_BRAKE_FORCED_RELEASE_FAILURE: 40118004,
  WSP_119_PRIVATE_ERROR: 40119001,
  WSP_132_WRITE_FAILED: 40132001,
  WSP_134_START_PROCESS_ERROR: 40134001,
  WSP_134_CHECK_PROCESS_ERROR: 40134002,
  WSP_134_CHECK_PROCESS_UNEXPECTED_ERROR: 40134003,
  WSP_141_UNABLE_TO_START: 40141001,
  WSP_141_UNABLE_TO_END: 40141002,
  WSP_953_WRITE_FAILED: 40953001,
  WSP_ROUTINE_FAILED_ERROR: 40020001,
  WSP_WRITE_VARIANT_MODE_OFF_ERROR: 40037001,
  WSP_EXECUTION_NON_RESPONSE_ERROR: 40046001,
  WSP_NON_RESPONSE_ERROR: 40048001,
  WSP_NG_RESPONSE_ERROR: 40048001,
  WSP_EXECUTION_TIMEOUT_ERROR: 40049001,
  WSP_READ_FAILED_ERROR: 40049002,
  WSP_DETECT_DTC_ERROR: 40057001,
  WSP_ABORT_ERROR: 40057002,
  WSP_DB_VARIANT_DATA_NOT_FOUND: 40063001,
  WSP_EXECUTION_RADAR_CONTROL_FAILURE_ERROR: 40086001,
  WSP_EXECUTION_RADAR_CONTROL_ENVIRONMENT_FAILURE_ERROR: 40086002,
  WSP_EXECUTION_RADAR_CHECK_FAILURE_ERROR: 40087001,
  WSP_EXECUTION_RADAR_CHECK_ENVIRONMENT_FAILURE_ERROR: 40087002,
  WSP_EXECUTION_RADAR_CLEAR_FAILURE_ERROR: 40088001,
  WSP_EXECUTION_ANTENNA_OUTPUT_VALUE_SETTING_FAILURE_ERROR: 40971001,
  WSP_EXECUTION_CHECK_MODE_SHIFT_FAILURE_ERROR: 40982001,
  WSP_EXECUTION_CHECK_MODE_SHIFT_SECURITY_ACCESS_ERROR: 40982002,
  WSP_DTC_ERROR: 40058001,
  WSP_LIMIT_ERROR: 40058002,
  WSP_CONDITION_ERROR: 40058003,
  WSP_ABNORMAL_ERROR: 40058004,
  WSP_CONDITION_FAILURE_ERROR: 40943001,
  WSP_ES_NO_HISTORY_ERROR: 40946001,
  WSP_EXCESSIVE_WHEEL_SPEED_ERROR: 40109001,
  WSP_EPS_INFO_CLEAR_ERROR: 40099001,
  WSP_NOT_SUPPORTED_DID_ERROR: 40931001,
  WSP_PREREQUISITES_ERROR: 40136001,
  WSP_HEX_NON_DEFAULT_NG_ERROR: 40902001,
  WSP_HEX_SECURITY_ACCESS_ERROR: 40902002,
  WSP_HEX_ADDRESS_ERROR: 40902003,
  WSP_DEVELOP_HEX_NON_DEFAULT_NG_ERROR: 40914001,
  WSP_DEVELOP_HEX_SECURITY_ACCESS_ERROR: 40914002,
  WSP_DEVELOP_HEX_ADDRESS_ERROR: 40914003,

  // Data Monitor / Active Test
  DMAT_BACKEND_ACCESS_TO_INTDASH_FAILED: 50000000,
  DMAT_CLOUD_CONNECTOR_ACCESS_TO_INTDASH_FAILED: 50000001,
  DMAT_CLOUD_CONNECTOR_SYSTEM_ERROR: 50000002,

  // 既販車対応
  IMPORT_CSV_FILE_INVALID_EXTENSION: 60000000,
  IMPORT_CSV_FILE_INVALID_HEADER: 60000001,
  IMPORT_CSV_FILE_NO_DATA: 60000002,
  IMPORT_CSV_FILE_INVALID_DATA: 60000003,
  IMPORT_CSV_FILE_INVALID_DUPLICATED_ID: 60000004,
  IMPORT_CSV_FILE_INVALID_COUNTRY: 60000005,
  PAK_FILE_INVALID_EXTENSION: 60001000,
  PAK_FILE_NO_DATA_WITH_MATCHING_PART_NUMBER: 60001001,
  DB_PACK_FILE_INFO_NOT_FOUND: 60002000,
  DB_PACK_FILE_LIST_NOT_FOUND: 60002001,
  DB_PACK_FILE_INFO_DELETE_FAILED: 60002002,
  PACK_FILE_DELETE_FAILED: 60002003,
};